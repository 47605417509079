import React, { createContext, useContext, useState } from 'react';

const PermissionsContext = createContext();

export function PermissionsProvider({ children }) {
  const [userPermissions, setUserPermissions] = useState(
    localStorage.getItem('userData') ? 
      JSON.parse(localStorage.getItem('userData')) : '',
  );

  const changePermissions = (newPerms) => {
    if (newPerms) {
      setUserPermissions(newPerms);
      localStorage.setItem('userData', JSON.stringify(newPerms));
    } else {
      setUserPermissions('');
      localStorage.removeItem('userData');
    }
  };

  const contextValue = {
    userPermissions,
    changePermissions,
  };

  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  );
}

export function usePermissions() {
  return useContext(PermissionsContext);
}
