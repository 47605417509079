import React, { useState } from 'react';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';

import './MenuButton.scss'; // Import the SCSS file

function MenuButton({ children }) {
  const [clickYAtBottom, setClickYAtBottom] = useState(false);

  const handleMenuButtonClick = (event) => {
    if (event.clientY - 60 > window.innerHeight / 2) setClickYAtBottom(true);
    else setClickYAtBottom(false);
  };

  return (<div className="menu-container">
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className="menu-button" onClick={handleMenuButtonClick}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </Menu.Button>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={`menu-items
            ${clickYAtBottom ? 'menu-items-bottom' : ''}`}>
              {children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  </div>);
}

export default MenuButton;
