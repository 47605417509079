import React, { useState, useEffect } from 'react';

import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import CampaignConfirmation from './CampaignConfirmation';
import styles from './CreateCampaignForm.module.scss';
import { PAPERPLANES_ID } from '../../config';
import { useForm, FormProvider, useFormContext } from '../../hooks/useForm';
import { usePermissions } from '../../hooks/usePermissions';
import CampaignService from '../../services/CampaignService';
import CompanyService from '../../services/CompanyService';
import { DataSourceConnectionContent } from
  '../DataSourceConnectionContent/DataSourceConnectionContent';
import Form from '../Form/Form';
import Input from '../Form/Input';
import ABSplitField from '../Inputs/ABSplitField';
import ToggledNumberField from '../Inputs/ToggledNumberField';
import ModalPopUp from '../Modal/ModalPopUp';
import Spinner from '../Spinner/Spinner';

function useCompanyList() {
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyToIdMapping, setCompanyToIdMapping] = useState({});

  const loadCompanyList = async () => {
    const getCompanyListResult = await CompanyService.getCompanyList();
    if (getCompanyListResult.success) {
      const companies = getCompanyListResult.data.filter(
          (company) => company.status === 'Active',
      ).map((company) => {
        return {label: company.name, value: company.name};
      });
      setCompanyOptions(companies.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }));
      setCompanyToIdMapping(getCompanyListResult.data.reduce((acc, company) => {
        acc[company.name] = company.mIdsite;
        return acc;
      }, {}));
    } else {
      toast.error('Failed to load companies. Please try again.');
      setCompanyOptions([{label: 'No clients!', value: ''}]);
    }
  };
  
  return {
    companyOptions,
    companyToIdMapping,
    loadCompanyList,
  };
}

function useMailableCount(companyToIdMapping) {
  const [mailableCount, setMailableCount] = useState(0);
  const {userPermissions} = usePermissions();

  const getKlaviyoCount = async (client, listId) => {
    const response = await CampaignService.getMailableCustomers(
        companyToIdMapping[client] || userPermissions.companyId, listId);
    if (!response.success) toast.error('Segment ID doesn\'t exist');
    setMailableCount(response.count);
    return response.success;
  };

  const getABDCount = async () => true;

  const getMailableCount = async (campaignType, company, listId) => {
    return campaignType === 'WIB' ? getKlaviyoCount(company, listId) : getABDCount();
  };

  return {
    mailableCount, getMailableCount,
  };
}

function useCreativeList(companyToIdMapping) {
  const [letterPaths, setLetterPaths] = useState([]);
  const [envelopePaths, setEnvelopePaths] = useState([]);
  const { userPermissions } = usePermissions();
  
  const loadLetterPaths = async (company) => {
    const getLetterPaths = await CompanyService.getCompanyLetterPaths(
        companyToIdMapping[company] || userPermissions.companyId);

    if (getLetterPaths.success) {
      setLetterPaths(getLetterPaths.data.map((letterPath, index) => {
        return {label: letterPath, value: letterPath, selected: index == 0};
      }));
    } else {
      toast.error('Failed to load company letter templates. Please try again');
    }
    return getLetterPaths.success;
  };

  const loadEnvelopePaths = async (company) => {
    const getEnvelopePaths = await CompanyService.getCompanyEnvelopePaths(
        companyToIdMapping[company] || userPermissions.companyId,
    );

    if (getEnvelopePaths.success) {
      setEnvelopePaths(getEnvelopePaths.data.map((envelopePath, index) => {
        return {label: envelopePath, value: envelopePath, selected: index == 0};
      }));
    } else {
      toast.error('Failed to load company envelope templates. Please try again');
    }
    return getEnvelopePaths.success;
  };

  return {
    letterPaths, loadLetterPaths, envelopePaths, loadEnvelopePaths,
  };
}

function StepIndicator({ stepLabels, currentStep }) {
  return (<ul className={styles.stepIndicator}>
    {stepLabels.map((step, index) => <li key={index} 
      className={`${styles.step} ${index >= currentStep ? styles.greyLine : ''}`}>
      <FontAwesomeIcon
        icon={index < currentStep ? faCircleCheck : faCircle}
        color={index - 1 < currentStep ? '#5b9bd4' : '#959595'}
        size='xl'
      />
      {index >= currentStep && <span className={styles.stepNumber}>{index + 1}</span>}
      <div className={styles.stepLabel}>{step}</div>
    </li>)}
  </ul>);
}

const campaignToDataSourceMap = {
  'WIB': 'klaviyo',
  'ABD': 'crm',
};

function CreateCampaignForm({ isOpen, close, onSubmit }) {
  const { companyOptions, companyToIdMapping, loadCompanyList } = useCompanyList();
  const { mailableCount, getMailableCount } = useMailableCount(companyToIdMapping);
  const { letterPaths, loadLetterPaths, envelopePaths, loadEnvelopePaths } = useCreativeList(companyToIdMapping);
  const [loading, setLoading] = useState(false);
  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;

  useEffect(() => {
    if (isPPAccount) {
      const fetchData = async () => {
        setLoading(true);
        await loadCompanyList();
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  const methods = useForm({
    'campaign_type': 'WIB',
    'is_one_time': '0',
    'company': '',
    'campaign_name': '',
    'list_id': '',
    'target_days_ago': 7,
    'target_from_date': '', 'target_to_date': '',
    'suppression_period_enabled': true, 'suppression_period': 30,
    'control_split_enabled': true, 'control_split': 15,
    'daily_send_limit_enabled': false, 'daily_send_limit': '',
    'is_a_b_split': false,
    'a_b_split': 50,
    'variant_a_name': '', 'variant_b_name': '',
    'letter_path_a': '', 'letter_path_b': '',
    'envelope_path_a': '', 'envelope_path_b': '',
  });
  const formData = methods.formData;
  const [stepIndex, setStepIndex] = useState(0);

  const createCampaign = async (data) => {
    try {
      const submitData = {...data};
      if (!isPPAccount) submitData.company = userPermissions.companyName;
      submitData['is_one_time'] = submitData['is_one_time'] === '1';
      if (!submitData['is_a_b_split']) submitData['a_b_split'] = 100;
      if (submitData['campaign_type'] !== 'WIB') submitData['list_id'] = '0';
      if (!submitData['control_split_enabled']) submitData['control_split'] = 0;
      if (!submitData['suppression_period_enabled']) submitData['suppression_period'] = undefined;
      if (!submitData['daily_send_limit_enabled']) submitData['daily_send_limit'] = undefined;
      const response = await CampaignService.createCampaign(submitData);
      if (response.success) {
        onSubmit();
        handleClose();
      } else {
        if (response.error && response.error.errors) {
          response.error.errors.map((err) => toast.error(err.detail));
        }
      }
    } catch (e) {
      toast.error('An error occured when creating the campaign');
    }
  };

  const handleClose = () => {
    methods.reset();
    close();
  };

  const handleNext = async (validationSchema) => {
    setLoading(true);
    let valid = true;
    if (validationSchema) valid = methods.validate(validationSchema);
    if (valid && steps[stepIndex].execute) {
      valid = await steps[stepIndex].execute();
    }
    if (valid) {
      stepIndex === steps.length-1 ? await createCampaign(formData) : setStepIndex(stepIndex+1);
    }
    setLoading(false);
  };

  const handlePrev = () => {
    stepIndex === 0 ? handleClose() : setStepIndex(stepIndex-1);
  };

  const stepLabels = ['Settings', 'Creative', 'Activation'];
  
  const steps = [
    {
      step: 0,
      render: () => <CampaignTypeForm onSubmit={handleNext} />,
    },
    {
      step: 0, title: 'Campaign Settings',
      render: () => <CampaignSettingsForm onSubmit={handleNext} companyOptions={companyOptions} />,
      execute: () => getMailableCount(formData['campaign_type'], formData['company'], formData['list_id']),
    },
    {
      step: 0,
      render: () => {
        return <Form id='campaignForm' onSubmit={handleNext}>
          <DataSourceConnectionContent mailableCount={mailableCount} />
        </Form>;
      },
      execute: () => {
        methods.reset(['letter_path_a', 'letter_path_b']);
        return loadLetterPaths(formData['company']);
      },
    },
    {
      step: 1,
      title: 'Letter Creative',
      render: () => <LetterPathForm onSubmit={handleNext} letterPaths={letterPaths} />,
      execute: () => {
        methods.reset(['envelope_path_a', 'envelope_path_b']);
        return loadEnvelopePaths(formData['company']);
      },
    },
    {
      step: 1,
      title: 'Outer Envelope Creative',
      render: () => <EnvelopePathForm onSubmit={handleNext} envelopePaths={envelopePaths} />,
      execute: () => {
        methods.setFormData({
          ...formData,
          'activation_time': new Date(new Date().getTime() + 5 * 60000).toISOString().slice(11, 16),
          'data_source': campaignToDataSourceMap[formData['campaign_type']],
        });
        return true;
      },
    },
    {
      step: 2,
      title: 'Campaign Summary',
      render: () => {
        return <Form id='campaignForm' onSubmit={handleNext}>
          <CampaignConfirmation data={formData} isABSplit={formData['is_a_b_split']} />
        </Form>;
      },
    },
  ];
  
  return (<>
    <ModalPopUp isOpen={isOpen} onClose={handlePrev} title='Add Campaign' disabled={loading}
      description={<>
        <StepIndicator stepLabels={stepLabels} currentStep={steps[stepIndex].step} />
        {steps[stepIndex].title && <p style={{marginTop: '20px'}}>{steps[stepIndex].title}</p>}
      </>}
    >
      <FormProvider value={methods}>
        {steps[stepIndex].render()}
      </FormProvider>
      <div className={styles.buttonRightContainer}>
        <button className={styles.formButton} type='button' onClick={handlePrev}>
          {stepIndex === 0? 'CLOSE' : 'PREV'}
        </button>
        <button className={styles.formButton} type='submit' form='campaignForm'>
          {stepIndex === steps.length-1? 'SUBMIT' : 'NEXT'}
        </button>
      </div>
      {loading && <Spinner loading={loading} />}
    </ModalPopUp>
  </>);
}

function CampaignTypeForm({ onSubmit }) {
  const campaignTypes = [
    {label: 'Klaviyo', value: 'WIB'},
    {label: 'Abandoned Baskets', value: 'ABD'},
  ];

  const validation = {
    'campaign_type': {required: 'Empty!'},
    'is_one_time': {required: 'Empty!'},
  };

  return (<Form id='campaignForm' onSubmit={() => onSubmit(validation)}>
    <Input type='radio' label='Campaign Type' name='campaign_type' choices={campaignTypes} tiled={true} />
    <Input type='dropdown' label='Audience Targeting' name='is_one_time'
      choices={[
        {value: '0', label: 'Daily Automation'},
        {value: '1', label: 'One Time Bulk Mailer'},
      ]}
    />
  </Form>);
}

function getCampaignSettingsValidationSchema(formData, isPPAccount) {
  const validation = {
    'campaign_name': {required: 'Empty!'},
  };
  const isOneTime = formData['is_one_time'] === '1';
  const isABD = formData['campaignType'] === 'ABD';

  if (isPPAccount) validation['company'] = {required: 'Empty!'};
  if (formData['campaign_type'] === 'WIB') validation['list_id'] = {required: 'Empty!'};
  if (isOneTime && isABD) validation['target_days_ago'] = {required: 'Empty!'};
  
  if (!isOneTime && isABD) {
    validation['target_from_date'] = {required: 'Empty!'};
    validation['target_to_date'] = {required: 'Empty!'};
  }
  if (formData['suppression_period_enabled']) {
    validation['suppression_period_enabled'] = {required: 'Empty!', min: 0, max: 365};
  }
  if (formData['control_split_enabled']) validation['control_split'] = {required: 'Empty!', min: 0, max: 99};
  if (formData['daily_send_limit_enabled']) validation['daily_send_limit'] = {required: 'Empty!', min: 0};
  if (formData['is_a_b_split']) {
    validation['variant_a_name'] = {required: 'Empty!'};
    validation['variant_b_name'] = {required: 'Empty!'};
    validation['a_b_split'] = {required: 'Empty!', min: 0, max: 100};
  }
  return validation;
}

function CampaignSettingsForm({ onSubmit, companyOptions }) {
  const { formData } = useFormContext();
  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;

  const campaignType = formData['campaign_type'];
  const isOneTime = formData['is_one_time'] === '1';
  const isABD = formData['campaign_type'] === 'ABD';
  
  return (<Form id='campaignForm' onSubmit={() => onSubmit(getCampaignSettingsValidationSchema(formData, isPPAccount))}>
    {isPPAccount && <Input type='dropdown' name='company' label='Client' placeholder='Select a Company' choices={companyOptions} />}
    <Input type='text' name='campaign_name' label='Campaign Name' placeholder='Name of the campaign' />
    {campaignType === 'WIB' && <Input type='text' name='list_id' label='Klaviyo Segment ID' placeholder='Segment ID' />}
    {!isOneTime && isABD &&
      <Input type='number' name='target_days_ago' label='Days since Abandoned Basket event'
        unit='days' min={0} max={30}
      />}
    {isOneTime && isABD && <>
      <Input type='date' name='target_from_date' label='Target from date' />
      <Input type='date' name='target_to_date' label='Target to date' />
    </>}
    <div style={{display: 'flex'}}>
      <Input type='checkbox' name='is_a_b_split' />
      <div style={{
        fontSize: 'small',
        height: '27px', marginLeft: '5px',
        alignContent: 'center',
      }}>A/B Split</div>
    </div>
    {formData['is_a_b_split'] && <ABSplitField />}
    <ToggledNumberField label='Suppression Period' name='suppression_period' min={0} max={365} unit='days' />
    <ToggledNumberField label='Control Split' name='control_split' min={0} max={100} unit='%' />
    <ToggledNumberField label='Daily Send Limit' name='daily_send_limit' min={0} unit='mails' />
  </Form>);
}

function LetterPathForm({ onSubmit, letterPaths }) {
  const { formData } = useFormContext();
  const validation = {
    'letter_path_a': {required: 'Empty!'},
  };
  if (formData['is_a_b_split']) validation['letter_path_b'] = {required: 'Empty!'};

  return (<Form id='campaignForm' onSubmit={() => onSubmit(validation)}>
    <Input type='dropdown' name='letter_path_a' placeholder='Select a Letter Path'
      label={formData['is_a_b_split'] ? 'Letter Path A' : 'Letter Path'} choices={letterPaths} />
    {formData['is_a_b_split'] && <Input type='dropdown' name='letter_path_b' placeholder='Select a Letter Path'
      label='Letter Path B' choices={letterPaths} />}
  </Form>);
}

function EnvelopePathForm({ onSubmit, envelopePaths }) {
  const { formData } = useFormContext();
  const validation = {
    'envelope_path_a': {required: 'Empty!'},
  };
  if (formData['is_a_b_split']) validation['envelope_path_b'] = {required: 'Empty!'};
  
  return (<Form id='campaignForm' onSubmit={() => onSubmit(validation)}>
    <Input type='dropdown' name='envelope_path_a' placeholder='Select an Envelope Path'
      label={formData['is_a_b_split'] ? 'Envelope Path A' : 'Envelope Path'} choices={envelopePaths} />
    {formData['is_a_b_split'] && <Input type='dropdown' name='envelope_path_b' placeholder='Select an Envelope Path'
      label='Envelope Path B' choices={envelopePaths} />}
  </Form>);
}

export default CreateCampaignForm;
