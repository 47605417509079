import React from 'react';

import FieldWrapper from './FieldWrapper';
import styles from './Input.module.scss';
import { useFormContext } from '../../hooks/useForm';

function Input({ type, label, name, disabled, placeholder, choices, min, max, unit, ...props }) {
  const { formData, setValue, errors } = useFormContext();
  let input;
  switch (type) {
    case 'checkbox':
      input = <input id={name} name={name} type={type} checked={formData[name]} 
        onChange={(e) => setValue(name, e.target.checked)} disabled={disabled}
      />;
      break;
    case 'dropdown':
      input = <select id={name} name={name}
        value={formData[name]} disabled={disabled}
        onChange={(e) => setValue(name, e.target.value)}
      >
        <option value='' disabled>{placeholder || 'Please select...'}</option>
        {choices.map((c, i) => <option key={i} value={c.value}>{c.label}</option>)}
      </select>;
      break;
    case 'number':
    case 'decimal':
      input = <span>
        <input type='number' id={name} name={name}
          value={formData[name]} disabled={disabled}
          onChange={(e) => setValue(name, e.target.value)}
          min={min} max={max}
          step={type === 'decimal' ? '0.01' : '1'}
        />
        {unit && <span className={styles.unitText}>{unit}</span>}
      </span>;
      break;
    case 'radio':
      input = <fieldset id={name} name={name} className={`${props.tiled ? styles.radioTile : ''}`}>
        {choices.map((c, i) => <div key={i}>
          <input id={`${name}-${c.value}`} name={name} type='radio' value={c.value}
            checked={c.value === formData[name]} disabled={disabled}
            onChange={() => setValue(name, c.value)}
          />
          <label htmlFor={`${name}-${c.value}`}>{c.label}</label>
        </div>)}
      </fieldset>;
      break;
    default:
      input = <input id={name} name={name} type={type} 
        placeholder={placeholder} 
        value={formData[name]} disabled={disabled}
        onChange={(e) => setValue(name, e.target.value)}
      />;
  };

  return (<FieldWrapper errorMessage={errors[name]}>
    {label && <label htmlFor={name}>{label}</label>}
    <div className={disabled ? styles.disabled : ''}>{input}</div>
  </FieldWrapper>);
}

export default Input;
