import ApiService from './ApiService';
import { clearAuthToken } from './AuthService';

const UserService = {
  getUserList: async () => {
    return await ApiService.get('/users/');
  },
  getUserDetails: async () => {
    return await ApiService.get(`/users/${userId}`);
  },
  deleteUser: async (userId) => {
    return await ApiService.delete(`/users/${userId}/`);
  },
  createUser: async (email, fName, lName, role, companyID) => {
    return await ApiService.post(`/users/`, {
      email,
      first_name: fName,
      last_name: lName,
      role,
      m_idsite: companyID,
    },
    );
  },
  updateUser: async (userId, fName, lName, role, companyID) => {
    return await ApiService.patch(`/users/${userId}/`, {
      first_name: fName,
      last_name: lName,
      role,
      m_idsite: companyID,
    },
    );
  },
  inviteUser: async (email) => {
    return await ApiService.post('/users/invite/', { email });
  },
  sendResetPasswordToken: async (email) => {
    return await ApiService.post('/users/password-reset/', { email });
  },
  resetPasswordConfirm: async (token, password) => {
    const response = await ApiService.post('/users/password-reset/confirm/', { password, token });

    if (response.success) {
      return { success: true };
    } else {
      return {
        success: false, error: response.error || 'Resetting password failed. Please try again.',
      };
    }
  },
  validateUrlToken: async (token) => {
    const response = await ApiService.post('/users/password-reset/validate_token/', { token });
    if (response.success) return { success: true };
    else return { success: false };
  },
  changeUserStatus: async (userId, status) => {
    return await ApiService.patch(`/users/${userId}/`, { 'status': status });
  },
  getCurrentUserData: async () => {
    const response = await ApiService.get(`/users/me/`);
    if (response.success) {
      return response;
    } else {
      clearAuthToken();
      return { success: false };
    };
  },
};

export default UserService;
