import React from 'react';

import styles from './FieldWrapper.module.scss';

function FieldWrapper({ errorMessage, children, className }) {
  return (<div className={`${styles.formGroup} ${errorMessage ? styles.error : ''} ${className}`}>
    {children}
    {errorMessage && <span className={styles.errorMessage}>
      {errorMessage}
    </span>}
  </div>);
}

export default FieldWrapper;
