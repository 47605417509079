import React, { useState, createContext, useContext } from 'react';

const FormContext = createContext(null);

function useForm(initialValues) {
  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const setValue = (field, value) => {
    setFormData({...formData, [field]: value});
    if (errors[field]) setErrors({...errors, [field]: null});
  };
  const clearErrors = () => setErrors({});
  const validate = (schema) => {
    const newErrors = {};
    Object.keys(schema).forEach((field) => {
      const s = schema[field];
      const v = formData[field];
      if (s.required && v === '') {
        newErrors[field] = s.required;
        return;
      }
      if (s.min !== null && parseFloat(v) < s.min) {
        newErrors[field] = `Must be greater than ${schema[v].min}`;
        return;
      }
      if (s.max !== null && parseFloat(v) > s.max) {
        newErrors[field] = `Must be less than ${schema[v].max}`;
        return;
      }
      if (s.pattern && !s.pattern.test(v)) {
        newErrors[field] = s.pattern.message;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const reset = (fieldsToReset=null) => {
    if (fieldsToReset) {
      // Reset only the specified fields
      const updatedFormData = { ...formData };
      fieldsToReset.forEach((field) => {
        if (field in initialValues) {
          updatedFormData[field] = initialValues[field];
        }
      });
      setFormData(updatedFormData);
    } else {
      // Reset all fields
      setFormData(initialValues);
    }
  };

  return {
    formData, setFormData, reset, setValue, 
    errors, clearErrors,
    validate,
  };
}

function FormProvider({ value, children }) {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

function useFormContext() {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
}

export { useForm, FormProvider, useFormContext };

