import React from 'react';

import { useFormContext } from '../../hooks/useForm';
import FieldWrapper from '../Form/FieldWrapper';
import Input from '../Form/Input';

function ABSplitField() {
  const { formData, setValue, errors } = useFormContext();

  return (<div>
    <div style={{display: 'flex'}}>
      <Input type='text' name='variant_a_name' label='Variant A Name' placeholder='Name for Variant A' />
      <FieldWrapper errorMessage={errors['a_b_split']}>
        <label htmlFor='a_b_split'>Probability for Variant A</label>
        <div><input type='number' id='a_b_split' name='a_b_split' value={formData['a_b_split']}
          onChange={(e) => setValue('a_b_split', e.target.value)}
          min={0} max={100} step='1'
        />
        <span style={{fontSize: 'small'}}>%</span></div>
      </FieldWrapper>
    </div>
    <div style={{display: 'flex'}}>
      <Input type='text' name='variant_b_name' label='Variant B Name' placeholder='Name for Variant B' />
      <FieldWrapper errorMessage={errors['a_b_split']}>
        <label htmlFor='b_split'>Probability for Variant B</label>
        <div><input type='number' id='b_split' name='b_split' 
          value={formData['a_b_split'] === '' ? '' : 100-formData['a_b_split']}
          onChange={(e) => e.target.value === '' ? setValue('a_b_split', '') : setValue('a_b_split', 100-e.target.value)}
          min={0} max={100} step='1'
        />
        <span style={{fontSize: 'small'}}>%</span></div>
      </FieldWrapper>
    </div>
  </div>);
}

export default ABSplitField;
