import React from 'react';

import styles from './CampaignConfirmation.module.scss';

function Record({ label, value }) {
  return (<div className={styles.record}>
    <label>{label}</label>
    <p className={styles.data}>{value}</p>
  </div>);
}

function CampaignConfirmation({ data, isABSplit }) {
  const showSuppressionPeriod = data['suppression_period_enabled'] || data['suppression_period_enabled'] === undefined;
  const showControlSplit = data['control_split_enabled'] || data['control_split_enabled'] === undefined;
  const showDailySendLimit = data['daily_send_limit_enabled'] || data['daily_send_limit_enabled'] === undefined;
  return (<div>
    {/* <p>Campaign Summary</p> */}
    <div className={styles.summaryGroup}>
      {data['company'] && <Record label='Company' value={data['company']} />}
      <Record label='Campaign Name' value={data['campaign_name']} />
      {data['campaign_type'] && <Record label='Campaign Type' 
        value={data['campaign_type'].charAt(0).toUpperCase() + data['campaign_type'].slice(1)} />}
      {data['list_id'] && <Record label='Segment ID' value={data['list_id']} />}

      {data['target_days_ago'] && <Record label='Target days ago' value={data['target_days_ago']} />}

      {data['target_from_date'] && <Record label='Target from date' value={data['target_from_date']} />}
      {data['target_to_date'] && <Record label='Target to date' value={data['target_to_date']} />}

      {showSuppressionPeriod && <Record label='Suppression Period' value={`${data['suppression_period']} days`} />}
      {showControlSplit && <Record label='Control Split' value={`${data['control_split']} %`} />}
      {showDailySendLimit && <Record label='Daily Send Limit' value={`${data['daily_send_limit']} mails per day`} />}
    </div>
    <div className={styles.summaryGroup}>
      <hr/>
      {isABSplit ? <>
        <Record label='Variant A Name' value={data['variant_a_name']} />
        <Record label='Variant A Probability' value={`${data['a_b_split']} %`} />
        <Record label='Letter Creative A' value={data['letter_path_a']} />
        <Record label='Outer Envelope Creative A' value={data['envelope_path_a']} />
        <hr/>
        <Record label='Variant B Name' value={data['variant_b_name']} />
        <Record label='Variant B Probability' value={`${100-data['a_b_split']} %`} />
        <Record label='Letter Creative B' value={data['letter_path_b']} />
        <Record label='Outer Envelope Creative B' value={data['envelope_path_b']} />
      </> :
      <>
        <Record label='Letter Creative' value={data['letter_path_a']} />
        <Record label='Outer Envelope Creative' value={data['envelope_path_a']} />
      </>}
    </div>
    <div className={styles.summaryGroup}>
      <hr />
      <Record label='Activation Time (UTC)' value={data['activation_time']} />
      <Record label='Data Source' value={data['data_source'].charAt(0).toUpperCase() + data['data_source'].slice(1)} />
    </div>
  </div>);
}

export default CampaignConfirmation;
