import React, { useState, useEffect } from 'react';

import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import styles from './ServiceConnection.module.scss';
import PrimaryActionButton from '../../components/Buttons/PrimaryActionButton';
import { DataSourceConnectionContent } from
  '../../components/DataSourceConnectionContent/DataSourceConnectionContent';
import ModalMultipartForm from '../../components/Modal/ModalMultipartForm';
import Spinner from '../../components/Spinner/Spinner';
import ApiKeyService from '../../services/ApiKeyService';

function StatusIcon({ status, lastSync }) {
  let icon;
  let text;
  const formattedDate = lastSync.slice(0, 16).replace('T', ' ');
  switch (status) {
    case 'success':
      icon = <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} style={{color: 'green'}} />;
      text = `Last synchronization: ${formattedDate}`;
      break;
    case 'error':
      icon = <FontAwesomeIcon icon={faCircleXmark} className={styles.icon} style={{color: 'red'}} />;
      text = `Error synchronizing products. Please check your email for more details.\nLast synchronization: ${formattedDate}`;
      break;
    case 'in_progress':
      icon = <Spinner fullscreen={false} size={14} />;
      text = 'Product sync in progress...';
  }
  return (<span className={styles.tooltip}>
    {icon}
    <span className={styles.tooltipText}>{text}</span>
  </span>);
}

function ServiceConnection({
  name,
  logo,
  text,
  document,
  formFields,
  status,
}) {
  const [secret, setSecret] = useState({});
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  const displayName = name[0].toUpperCase() + name.slice(1);

  const loadSecret = async () => {
    setLoading(true);
    const checkSecret = await ApiKeyService.checkApiKey(name);
    if (checkSecret.success) {
      setSecret(checkSecret.data);
    } else {
      toast.error(`Failed to check whether ${displayName} Secret already exist.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSecret();
  }, []);

  const maskValue = (value) => {
    if (value.length <= 4) return value;
    const index = value.length-4;
    return '*'.repeat(index)+value.slice(index);
  };
  
  const uploadSecret = async (...args) => {
    setLoading(true);
    const keys = {};
    formFields.forEach((field, i) => keys[field.name] = args[i]);
    const body = {
      'service': name,
      'secret': keys,
    };
    const response = await ApiKeyService.uploadApiKey(body);
    if (response.success) {
      // mask secret values
      formFields.forEach((field) => {
        if (keys[field.name].startsWith('**')) {
          keys[field.name] = secret.value[field.name];
        } else {
          keys[field.name] = maskValue(keys[field.name]);
        }
      });
      setSecret({exists: true, value: keys});
      setLoading(false);
      return true;
    } else {
      toast.error(`Failed to upload ${displayName} Secret.`);
      setLoading(false);
    }
    return false;
  };

  return (<>
    <div className={styles.connectContainer}>
      <div className={styles.connectInfo}>
        <div>
          <img src={logo} />
        </div>
        <div>
          <span>{displayName} {status && <StatusIcon 
            status={status.lastSynchronizedStatus}
            lastSync={status.lastSynchronizedDate} />}</span> <br />
          Connect Paperplanes to your {text}.
          <a href={document}
            target="_blank" rel="noopener noreferrer">Learn More</a>
        </div>
      </div>

      {<PrimaryActionButton
        disabled={loading}
        className={styles.connectButton}
        type='secondary'
        onClick={() => setForm({...form, isOpen: true})}
      >
        {loading ?
          <Spinner loading={loading} fullscreen={false} size={20} color='#FFFFFF' />
          : secret.exists ? 'Update' : 'Connect'}
      </PrimaryActionButton>}
    </div>
    <ModalMultipartForm
      isOpen={form.isOpen}
      title={`Add ${displayName} API Keys`}
      onSubmit={() => setForm({...form, isOpen: false})}
      onClose={() => setForm({...form, isOpen: false})}
      formData={form.data}
      setFormData={(data) => setForm({...form, data: data})}
      indexFormPageOpen={form.index}
      setIndexFormPageOpen={(index) => setForm({...form, index: index})}
      formConfigs={[
        {
          content: <div className={styles.note}>Values are masked with stars for security, 
          submitting mask values will keep them unchanged</div>,
          execute: uploadSecret,
          execArgsFormKeys: formFields.map((field) => field.name),
          config: formFields.map((field) => {
            return {
              ...field, 
              defaultValue: secret.value?.[field.name] || '',
              validation: {
                required: 'Empty!',
              },
            };
          }),
        },
        {
          title: `${displayName} API Keys`,
          content: <DataSourceConnectionContent />,
        },
      ]}
    />
  </>
  );
}

export default ServiceConnection;
